import logo from './logo.png';
import kakao from './kakao.png';
import naver from './naver.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="Title">
        <img src={logo} className="Logo" alt="logo" />
        <a href="/">
          <h1 className="Name">문상준 치과의원</h1>
        </a>
      </header>
      <div className="Subtitle">
        <h2 className="Goal">진심으로 임하는 가족 같은 치과</h2>
        <a href="https://bit.ly/3wNdVib" target="_blank" rel="noopener noreferrer">
          <img src={kakao} className="Kakao" alt="kakao" />
        </a>
        <a href="https://bit.ly/3lVtvap" target="_blank" rel="noopener noreferrer">
          <img src={naver} className="Naver" alt="naver" />
        </a>
      </div>
      <div className="Hours">
        <h2>진료 시간</h2>
        <p>💡 월 화 수 금 : 10:00 - 18:30</p>
        <p>💡 목 : 14:00 - 20:30</p>
        <p>💡 토 : 10:00 - 13:30</p>
      </div>
      <div className="Location">
        <h2>오시는 길</h2>
        <p>💡 인천 남동구 간석 4동 894-4 제일코아 2층</p>
        <p>
          <a>💡 </a>
          <a href="https://bit.ly/3sRQkvV" target="_blank" rel="noopener noreferrer">
            네이버 지도 보기
          </a>
        </p>
        <p>
          <a>💡 </a>
          <a href="https://bit.ly/3LE1Mld" target="_blank" rel="noopener noreferrer">
            카카오 지도 보기
          </a>
        </p>
      </div>
      <footer>
        대표 : 문상준 | 전화번호 : 032-432-2875
      </footer>
    </div>
  );
}

export default App;
